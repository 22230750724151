import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/announcing-more-control-backups-restores",
  "date": "21st May 2024",
  "title": "Announcing More Control for Backups & Restores",
  "summary": "Announcement Skpr's new features for better control over backups and restores.",
  "author": "Parth Gohil",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "announcement"
  }, {
    "name": "backup"
  }, {
    "name": "restore"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We’re making developers lives easier by allowing granular control of their backups and restores. The backup command still handles both backups together, but there are times when you simply need to backup files (volumes) or just backup and restore databases.`}</p>
    <p>{`We noticed that developers had the need to restore parts of the backup when testing as opposed to restoring both database and files together. The new functionality allows developers to target exactly what they need.`}</p>
    <p>{`With the new release v0.28.0 you’ll be able to:`}</p>
    <ul>
      <li parentName="ul">{`List, backup and restore a MySQL database`}</li>
      <li parentName="ul">{`List, backup and restore volumes`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/posts/graphic-terminal-backup-restore-workflow.gif",
        "alt": "Terminal output showing the workflow of backup restore of volume and mysql via a terminal"
      }}></img></p>
    <h2>{`The technical benefits`}</h2>
    <h3>{`Granular Control`}</h3>
    <p>{`You can now individually backup and restore the MySQL database of the application or just the Volumes (files and other assets). This means that when you don’t need to restore either one, you can simply backup and restore the one you need to, saving time and complexity in the process.`}</p>
    <h3>{`Speed`}</h3>
    <p>{`Now that you can split backups, there’s no need to wait for them all to finish–saving on time.`}</p>
    <h3>{`More transparency`}</h3>
    <p>{`The ability to just list the MySQL, volume backup or restore processes helps identify which is taking longer or which one is holding up the backup or the restore. This can help debug or identify issues with either (database or volumes) when running the backups or restores.`}</p>
    <h3>{`CI/CD Pipeline friendly`}</h3>
    <p>{`Now that we have granular control over what we backup, we can integrate into new places in the CI/CD pipeline e.g. Database backup before deployment. This way we can backup what we truly need to, saving time on nightly backup pipelines and optimising storage.`}</p>
    <h2>{`Your Next Steps`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.skpr.io/using-skpr/mysql/mysql-backup-create/"
        }}>{`MySQL Backup Create`}</a>{`: Create MySQL backups for an environment.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.skpr.io/using-skpr/mysql/mysql-restore-create/"
        }}>{`MySQL Restore Create`}</a>{`: Restore a MySQL backup to an environment.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.skpr.io/using-skpr/volume/volume-backup-create/"
        }}>{`Volume Backup Create`}</a>{`: Create Volume backups for an environment.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.skpr.io/using-skpr/volume/volume-restore-create/"
        }}>{`Volume Restore Create`}</a>{`: Restore a Volume backup to an environment.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      